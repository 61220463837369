import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';

export type ContentfulImage = {
  title: string;
  height: number;
  width: number;
  //image: ImageDataLike;
  objectPosition?: number | string;
  imageData?: IGatsbyImageData;
};

const useContentfulImage = (contentfulImage): ContentfulImage => {
  if (!contentfulImage) return null;

  const {
    title,
    gatsbyImageData: image,
    gatsbyImageData: { width, height },
  } = contentfulImage;

  const imageData = getImage(image);

  return {
    title,
    height,
    width,
    //image,
    imageData,
  };
};

export default useContentfulImage;
