import React from 'react';
import Layout from '@components/Layout/Layout';
import TextHero from '@components/TextHero';
import { graphql } from 'gatsby';
import { ContentfulTextSection } from '../../graphql-types';

import SEO from '@components/seo';
import Container from '@src/components/Container';
import useContentfulImage from '@src/hooks/useContentfulImage.hook';
import paketPages from '@src/data/paketPages';
import { GatsbyImage } from 'gatsby-plugin-image';
import TextArticle from '@src/components/TextArticle/TextArticle';
import Illustration from '@src/components/Illustration/Illustration';
import useHasMounted from '@src/lib/useHasMounted';

function Samhallsansvar({ data }) {
  const {
    contentfulPage: { headLine, pageHeader, pageContent, heroImage },
  } = data;

  const cardImage = useContentfulImage(heroImage);

  const text = pageContent[0] as ContentfulTextSection;
  const bottomImg = text.image && useContentfulImage(text.image);
  const mounted = useHasMounted();
  const imgOpacity = mounted ? 0.5 : '0 !important';

  return (
    <Layout theme="black">
      <SEO title={headLine} />
      <Container style={{ marginTop: 52, zIndex: 1, position: 'relative' }}>
        <TextHero
          theme="dark"
          title={pageHeader}
          //backgroundImage={cardImage}
          largeSubtitle
          leftAligned
          style={{ marginTop: 154, marginBottom: 32 }}
        ></TextHero>
      </Container>
      <div style={{ position: 'relative' }}>
        <GatsbyImage
          loading="eager"
          imgStyle={{
            objectPosition: 'center 7%',
          }}
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: 600,
            zIndex: 0,
            opacity: imgOpacity,
          }}
          image={cardImage.imageData}
          alt={cardImage.title}
        />
      </div>
      <Container>
        <TextArticle
          alignment="right"
          theme="black"
          style={{
            marginBottom: 105,
            marginTop: 102,
          }}
        >
          {text.content.content}
        </TextArticle>
        {bottomImg && (
          <GatsbyImage
            style={{
              marginRight: 'auto',
              marginBottom: 180,
            }}
            image={bottomImg.imageData}
            alt={bottomImg.title}
          />
        )}
        <Illustration name="Percent2" />
      </Container>
    </Layout>
  );
}

export default Samhallsansvar;

export const query = graphql`
  query SamhallsAnsvarQuery($locale: String) {
    contentfulPage(
      pageUrl: { eq: "/samhallsansvar" }
      node_locale: { eq: $locale }
    ) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
      pageHeaderSubtitle {
        pageHeaderSubtitle
      }
      heroImage {
        title
        gatsbyImageData(width: 1000)
      }
      pageContent {
        ... on ContentfulTextSection {
          title
          content {
            content
          }
          image {
            title
            gatsbyImageData(width: 688)
          }
        }
      }
    }
  }
`;
